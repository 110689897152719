import React, { useState, useEffect, useContext } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
//
import { DomainContext, LangIdContext } from "../../App";
import { validate } from "../login/validate";
// components
import AlertSuccess from "../Dashbord/AlertSuccess/AlertSuccess";
//
import google from "../../images/home-body/google play.svg";
import apple from "../../images/home-body/apple store.svg";
// config
import configData from "../../config/config.json";

const Application = () => {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 3000);
  };

  // for validation
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [emailData, setEmailData] = useState({ email: "" });
  const [disable, setDisable] = useState(false);

  const inputChangeHandler = (event) => {
    setDisable(false);
    setEmailData({ email: event.target.value });
  };

  useEffect(() => {
    setErrors(validate(emailData, "application"));
  }, [emailData, touched]);

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    // Email: emailData,
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (!Object.keys(errors).length) {
      setDisable(true);

      axios
        // .get(domain + "/api/app-request", {
        //   headers: header,
        // })
        .post(
          configData.API_DOMAIN + "/api/v1/DownloadRequests",
          {
            email: emailData.email,
          },
          {
            headers: header,
          }
        )
        .then((response) => {
          setDisable(false);
          if (response.data.isSuccess) {
            // success
            delete errors.email;
            alertHandeler();
            setEmailData({ email: "" });
            setTouched({
              email: false,
            });
          }
        })
        .catch((error) => {
          // alert(error);
        });
    } else {
      setTouched({
        email: true,
      });
    }
  };

  return (
    <div className="app-center app-btn-center">
      <div className="my-33 mb-xs-16 text-center app-text color-434343">
        <span className="fs-20 fw-bold fs-xs-14">دانلود اپلیکیشن گیشنیز</span>
        {/* <br /> */}
        <p className="fs-18 fs-xs-14 mb-0 pt-md-4 mt-2 line-heightf19">
          اپلیکیشن «گیشنیز» به زودی آماده می‌شود و می‌توانید آن را دانلود کنید.
          اگر می‌خواهید از اولین کاربرهای آن باشید،{" "}
          <br className="d-none d-lg-block" /> ایمیل‌تان را در کادر زیر وارد
          کنید که به محض آماده شدن اپلیکیشن، شما را در جریان قرار دهیم.
        </p>
      </div>
      <input
        type="email"
        // dir="ltr"
        placeholder="ایمیل خود را وارد کنید"
        value={emailData.email}
        onChange={inputChangeHandler}
        className={
          errors.email && touched.email
            ? "app-input border-error font-family-en"
            : "app-input font-family-en"
        }
      />
      {errors.email && touched.email && (
        <span className="input-error mx-0 error-app">{errors.email}</span>
      )}
      <br />

      <MDBBtn
        onClick={submitHandler}
        disabled={disable}
        className="mt-27 app-btn hov-shadow-btn shadow-off my-xs-3 mb-60"
      >
        ثبت
      </MDBBtn>

      <div className="d-flex text-center justify-content-center">
        <a href="javascript:void(0);">
          <LazyLoadImage
            // PlaceholderSrc={
            //   "https://cdn.gishniz.com/business/1/1820/Cover_202281510232610557514.png"
            // }
            src={google}
            alt="google play"
            className="app-img ms-2 hov-shadow2 hov-trans"
          />
        </a>
        <a href="javascript:void(0);">
          <LazyLoadImage
            // PlaceholderSrc={
            //   "https://cdn.gishniz.com/business/1/1820/Cover_202281510232610557514.png"
            // }
            src={apple}
            alt="apple store"
            className="app-img me-2 hov-shadow2 hov-trans"
          />
        </a>
      </div>
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={" ایمیل‌ شما با موفقیت ثبت شد."}
      />
    </div>
  );
};

export default Application;
