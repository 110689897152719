import React from "react";
import Carousel from "react-material-ui-carousel";
import { MDBCardImage } from "mdb-react-ui-kit";

function ImageCarousel({ photos, bizName }) {
  let photoList = [];

  if (photos.length > 1) {
    photos.map((item) => {
      if (item.isCover) {
        photoList.push(item);
      }
    });

    photos.map((item) => {
      if (!item.isCover) {
        photoList.push(item);
      }
    });
  } else {
    photoList = photos;
  }

  return (
    <Carousel
      autoPlay={false}
      indicators={photos.length > 1}
      navButtonsAlwaysVisible={false}
      navButtonsAlwaysInvisible={true}
      animation="slide"
      indicatorContainerProps={{
        style: {
          marginTop: "0px",
          position: "absolute",
          bottom: "0px",
          zIndex: 1,
        },
      }}
    >
      {photoList.map((item) => (
        <Item key={item.bpId} src={item.photoPath} bizName={bizName} />
      ))}
    </Carousel>
  );
}

export default ImageCarousel;

function Item(props) {
  return (
    <MDBCardImage
      className="business-card-img img-fluid"
      src={
        props.src.includes(".png")
          ? props.src.replace(".png", "_302x302.png")
          : props.src.replace(".jpg", "_302x302.jpg")
      }
      alt={props.bizName}
    />
  );
}
