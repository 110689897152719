import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import SocialMediaIcons from "./SocialMediaIcons";

import phone from "../../images/business/phone-call.svg";
import cellphone from "../../images/business/cell-phone.svg";
import fax from "../../images/business/fax.svg";
import globe from "../../images/business/globe.svg";
import email from "../../images/business/email.svg";
import timeicon from "../../images/business/time.svg";
import arrow from "../../images/business/arrow.svg";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${"#8ef0c0"}`,
  "&:first-of-type": {
    borderTop: `0px solid ${"#8ef0c0"}`,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#f7f7f7",
  paddingRight: "30px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#f7f7f7",
  padding: "0 9px 10px 72px",
}));

const BusinessAccordion = (props) => {
  // const [time, setTime] = useState([
  //   { StartTime: "", EndTime: "" },
  //   { StartTime: "", EndTime: "" },
  //   { StartTime: "", EndTime: "" },
  //   { StartTime: "", EndTime: "" },
  //   { StartTime: "", EndTime: "" },
  //   { StartTime: "", EndTime: "" },
  //   { StartTime: "", EndTime: "" },
  // ]);

  // useEffect(() => {

  //   if (props.data.length > 0) {
  //     setTime(WorkingDaysall);
  //   }
  // }, []);

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel, open) => (event, newExpanded) => {
    if (open) {
      setExpanded(newExpanded ? panel : false);
    }
  };
  if (props.data.length > 0) {
    const { PhoneNumbers } = props.data;
    // console.log(PhoneNumbers[0].Number);
  }

  // new code
  // let WorkingDaysall = props.data.workingDays;
  // const [time, setTime] = useState(WorkingDaysall);
  // console.log(time);
  const [icon, setIcon] = useState(false);

  const [openPhone, setOpenPhone] = useState(false);

  const [PhoneNumbers, setPhoneNumbers] = useState(props.data.phoneNumbers);
  const [SocialMedia, setSocialMedia] = useState(props.data.socialMedias);
  const [workday, setworkday] = useState(props.data.workingDays);

  useEffect(() => {
    setPhoneNumbers(props.data.phoneNumbers);
    // if (props.data.phoneNumbers.length > 1) {
    //   setOpenPhone(true);
    // }
    // console.log(props.data.phoneNumbers.length);
  }, [props.data.phoneNumbers]);

  useEffect(() => {
    setSocialMedia(props.data.socialMedias);
  }, [props.data.socialMedias]);

  useEffect(() => {
    setworkday(props.data.workingDays);
  }, [props.data.workingDays]);

  return (
    <div dir="ltr" className="business-accordion">
      {PhoneNumbers != null && PhoneNumbers != undefined
        ? PhoneNumbers.length > 0 && (
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange(
                "panel1",
                PhoneNumbers != null && PhoneNumbers != undefined
                  ? PhoneNumbers.length > 1
                  : false
              )}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id={
                  PhoneNumbers != null &&
                  PhoneNumbers != undefined &&
                  PhoneNumbers.length > 1
                    ? "panel1d-header"
                    : "panel1d-header-svg-none"
                }
              >
                {/* <Typography>Collapsible Group Item #1</Typography> */}
                <div className="business-information-2" dir="ltr">
                  <div className="mb-0 d-flex">
                    <img
                      className="business-information-icon"
                      src={phone}
                      alt="phone"
                    />

                    <p className="business-information-text">
                      {PhoneNumbers != null &&
                      PhoneNumbers != undefined &&
                      PhoneNumbers.length > 0 ? (
                        <a
                          href={`tel:${PhoneNumbers[0].calling}`}
                          className="color-black mb-2 font-family-en"
                        >
                          {PhoneNumbers[0].showNumber}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {PhoneNumbers != null &&
                PhoneNumbers != undefined &&
                PhoneNumbers.length > 0
                  ? PhoneNumbers.map((item) =>
                      PhoneNumbers[0].calling != item.calling ? (
                        <div className="mb-0 d-flex ml-n42 ml-xs-n30 ">
                          <img
                            className="business-information-icon"
                            src={phone}
                            alt="phone"
                          />
                          <p
                            key={item.showNumber}
                            className="business-information-text ms-0  mb-2 pb-1 font-family-en"
                          >
                            <a
                              href={`tel:${item.calling}`}
                              className="color-black"
                            >
                              {item.showNumber}
                            </a>
                          </p>
                        </div>
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </AccordionDetails>
            </Accordion>
          )
        : ""}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2", false)}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          {/* <Typography>Collapsible Group Item #2</Typography> */}
          <div className="business-information-2 svg-none" dir="ltr">
            <div className="mb-0 d-flex">
              <img
                className="business-information-icon"
                src={globe}
                alt="globe"
              />
              <a
                target={
                  props.data.details[0].webSite == null ||
                  props.data.details[0].webSite == "" ||
                  props.data.details[0].webSite == "http://"
                    ? ""
                    : "_blank"
                }
                href={
                  props.data.details[0].webSite == null ||
                  props.data.details[0].webSite == "" ||
                  props.data.details[0].webSite == "http://"
                    ? "javascript:void(0);"
                    : props.data.details[0].webSite
                }
                className={
                  props.data.details[0].webSite == null ||
                  props.data.details[0].webSite == "" ||
                  props.data.details[0].webSite == "http://"
                    ? "business-information-text business-information-text-disable"
                    : "business-information-text"
                }
              >
                Website
                {props.data.details[0].webSite == null ||
                props.data.details[0].webSite == "" ||
                props.data.details[0].webSite == "http://" ? (
                  ""
                ) : (
                  <img
                    className="business-information-img"
                    src={arrow}
                    alt=""
                  />
                )}
              </a>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <p className="business-times"> www.gishniz.com</p>
          <p className="business-times"> www.gishniz2.com</p> */}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3", false)}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          {/* <Typography>Collapsible Group Item #3</Typography> */}
          <div className="business-information-2 svg-none" dir="ltr">
            <div className="mb-0 d-flex">
              <img
                className="business-information-icon"
                src={email}
                alt="email"
              />
              <a
                target={
                  props.data.details[0].email == null ||
                  props.data.details[0].email == ""
                    ? ""
                    : "_blank"
                }
                href={
                  props.data.details[0].email == null ||
                  props.data.details[0].email == ""
                    ? "javascript:void(0);"
                    : `mailto:${props.data.details[0].email}`
                }
                className={
                  props.data.details[0].email == null ||
                  props.data.details[0].email == ""
                    ? "business-information-text business-information-text-disable"
                    : "business-information-text"
                }
              >
                Email
                {props.data.details[0].email == null ||
                props.data.details[0].email == "" ? (
                  ""
                ) : (
                  <img
                    className="business-information-img"
                    src={arrow}
                    alt=""
                  />
                )}
              </a>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <p className="business-times">gishniz@gmail.com</p>
          <p className="business-times">gishniz2@gmail.com</p>
          <p className="business-times">gishniz3@gmail.com</p> */}
        </AccordionDetails>
      </Accordion>
      {SocialMedia != null && SocialMedia != undefined
        ? SocialMedia.length > 0 && (
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3", false)}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                {/* <Typography>Collapsible Group Item #3</Typography> */}
                <div
                  className="business-information-2 business-information-3 svg-none"
                  dir="ltr"
                >
                  <div className="mb-0 d-flex">
                    {SocialMedia != null && SocialMedia != undefined
                      ? SocialMedia.map((item) => (
                          <SocialMediaIcons
                            SocialMedia={SocialMedia}
                            icon1={item.icon}
                            icon2={item.iconHover}
                            item={item}
                          />
                        ))
                      : ""}

                    {/* {const iconn ={}} */}
                    {/* <a
                      // key={item.SMTitle}
                      href={
                        SocialMedia != null && SocialMedia != undefined
                          ? item.SMLink
                          : ""
                      }
                      className="business-information-social"
                      // className="business-information-social-insta"
                      onMouseOver={() => setIcon(iconhover)}
                      onMouseOut={() => setIcon(iconkhali)}
                    >
                      <img src={icon} alt=""  
                       
                      />
                    </a>
                    </> */}
                    {/* <a
                href={
                  SocialMedia != null && SocialMedia != undefined
                    ? SocialMedia[2].SMLink
                    : ""
                }
                className="business-information-social-insta"
              ></a> */}
                    {/* <a href="" className="business-information-social-tel"></a>
              <a
                href={
                  SocialMedia != null && SocialMedia != undefined
                    ? SocialMedia[3].SMLink
                    : ""
                }
                className="business-information-social-twit"
              ></a>
              <a href="" className="business-information-social-ytub"></a>
              <a
                href={
                  SocialMedia != null && SocialMedia != undefined
                    ? SocialMedia[0].SMLink
                    : ""
                }
                className="business-information-social-face"
              ></a> */}
                  </div>
                </div>
              </AccordionSummary>
            </Accordion>
          )
        : ""}

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4", true)}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          {/* <Typography>Collapsible Group Item #3</Typography> */}
          <div className="business-information-2" dir="ltr">
            <div className="mb-0 d-flex">
              <img
                className="business-information-icon"
                src={timeicon}
                alt="time"
              />
              <p className="business-information-text">
                {/* <span className="color-5ae6a2">open . </span>closes */}
                ساعات کاری
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="business-times d-flex justify-content-start">
            <div>
              <span className="business-times-day mb-2">Mon</span>
              <span className="business-times-day mb-2">Tue</span>
              <span className="business-times-day mb-2">Wed</span>
              <span className="business-times-day mb-2">Thu</span>
              <span className="business-times-day mb-2">Fri</span>
              <span className="business-times-day mb-2">Sat</span>
              <span className="business-times-day">Sun</span>
            </div>
            <div>
              <span className="ps-3 d-block mb-2">
                {workday != null && workday != undefined
                  ? workday.map((item) =>
                      item.workHours ? (
                        <div className="d-block mb-2" key={item.title}>
                          <span className="ps-3">{item.workHours}</span>
                        </div>
                      ) : (
                        <span className="ps-3 business-times-closed mb-2 d-block ">
                          closed
                        </span>
                      )
                    )
                  : ""}
              </span>

              {/* <span className="ms-1 ps-2 business-times-closed">
                  Now closed
                </span> */}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BusinessAccordion;
