import React, { useState, useEffect, useContext } from "react";
import {
  MDBBtn,
  MDBRow,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBBtnGroup,
} from "mdb-react-ui-kit";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import "./EBModals.css";
import Autocomplete from "@mui/material/Autocomplete";
import iconn from "../../images/next-left.png";
import iaddress from "../../images/business/address.svg";
import icall from "../../images/business/phone-call.svg";
import iglobe from "../../images/business/globe.svg";
import itime from "../../images/business/time.svg";
import add1 from "../../images/bg-green-add.png"; //////////////
import arroww from "../../images/business/arrow white.svg";
import closee from "../../images/business/close.svg";
import closee_black from "../../images/header/close.svg";
import map_bg from "../../images/business/map-bg.jpg";
import map_icon from "../../images/result/map.png";
import ibizname from "../../images/business/business-and-trade.svg";
import ibizjob from "../../images/business/tag (1).svg";
import idescription from "../../images/business/description.svg";
//phone part
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import axios from "axios";
import { DomainContext, LangIdContext } from "../../App";
// components
import DraggableMap from "../draggable-map/DraggableMap";
import AlertSuccess from "../Dashbord/AlertSuccess/AlertSuccess";
// config
import configData from "../../config/config.json";
//
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function EBModalM(props) {
  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const formatChar = {
    0: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  };
  // location data
  const [locationData, setLocationData] = useState({
    Lat: "",
    Lng: "",
  });
  // for success alert
  const [showAlert, setshowAlert] = useState(false);
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 3000);
  };

  // for modal
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => {
    setPhone([]);

    if (basicModal) {
      setBasicModal(false);
    } else {
      setBasicModal(true);
      document.getElementById("firstM").style.display = "block";
      document.getElementById("NameM").style.display = "none";
      document.getElementById("JobM").style.display = "none";
      document.getElementById("AddressM").style.display = "none";
      document.getElementById("callM").style.display = "none";
      document.getElementById("siteM").style.display = "none";
      document.getElementById("timeM").style.display = "none";
      document.getElementById("discriptionM").style.display = "none";
    }
  };
  const Handeler = (id) => {
    document.getElementById("firstM").style.display = "none";
    document.getElementById(id).style.display = "block";
  };
  const goFirst = (id) => {
    document.getElementById("firstM").style.display = "block";
    document.getElementById(id).style.display = "none";
  };

  // datas
  const [data, setData] = useState({
    address: "",
    phone: { 0: 0 },
    website: { 0: "" },
    time: {},
    desc: "",
    Name: "",
    FName: "",
    Job: "",
    FJob: "",
  });
  const [saveName, setSaveName] = useState(false);
  const [saveJob, setSaveJob] = useState(false);
  const [saveAdd, setSaveAdd] = useState(false);
  const [savePhone, setSavePhone] = useState(false);
  const [saveWeb, setSaveWeb] = useState(false);
  const [saveDesc, setSaveDesc] = useState(false);
  const [saveTime, setSaveTime] = useState(false);

  // this 2 are for input adder web
  const [showCounter, setShowCounter] = useState(true);
  const [counter, setCounter] = useState(1);
  const handleClick = () => {
    if (counter < 4) {
      setCounter(counter + 1);
    }
    if (counter > 2) {
      setShowCounter(false);
    }
  };
  const handleOnChange = (e) => {
    const abc = {};
    abc[e.target.id] = e.target.value;
    // setInputValues({ ...inputValues, ...abc });
    setData({ ...data, website: { ...data.website, ...abc } });
    // console.log(data.website);
    setSaveWeb(true);
  };

  // this 2 are for input adder phone
  const [showCounter2, setShowCounter2] = useState(true);
  const [counter2, setCounter2] = useState(1);
  const handleClick2 = () => {
    if (counter2 < 4) {
      setCounter2(counter2 + 1);
    }
    if (counter2 > 2) {
      setShowCounter2(false);
    }
  };
  const handleClick2Delete = (index) => {
    if (counter2 > PhoneLength) {
      if (counter2 <= 4) {
        setShowCounter2(true);
      }
      setPhone({
        ...Phone,
        [index]: "",
      });
      setCounter2(counter2 - 1);
    }
  };
  const handleOnChange2 = (event) => {
    const abc = {};
    abc[event.target.id] = event.target.value;
    // setInputValues({ ...inputValues, ...abc });
    setData({ ...data, phone: { ...data.phone, ...abc } });
    setPhone({ ...Phone, ...abc });

    // console.log(data.phone);
    setSavePhone(true);
  };

  // for address input
  const handleAdd = (e) => {
    setData({ ...data, address: e.target.value });
    setAdd(e.target.value);

    setSaveAdd(true);
  };

  // for Name and Job input
  const handleNameAndJob = (e) => {
    if (e.target.name == "EnJobTitle" || e.target.name == "FaJobTitle") {
      setTitle({ ...Title, [e.target.name]: e.target.value });
      setSaveJob(true);
    } else if (e.target.name == "EnTitle" || e.target.name == "FaTitle") {
      setTitle({ ...Title, [e.target.name]: e.target.value });
      setSaveName(true);
    }
  };

  // for description input
  const handleDesc = (e) => {
    if (e.target.name == "FDes") {
      setDes({ ...Des, FDes: e.target.value });
    } else if (e.target.name == "EDec") {
      setDes({ ...Des, EDes: e.target.value });
    }
    // setData({ ...data, desc: e.target.value });
    setSaveDesc(true);
  };

  // for times
  //shanbe
  const [shanbe, setshanbe] = useState({ startTime: null, endTime: null });
  const startTimeShanbe = (e) => {
    setshanbe({ ...shanbe, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimeShanbe = (e) => {
    setshanbe({ ...shanbe, endTime: e.target.value });
    setSaveTime(true);
  };
  const [shanbeF, setshanbeF] = useState(false);

  //yekshanbe
  const [yekshanbe, setyekshanbe] = useState({
    startTime: null,
    endTime: null,
  });
  const startTimeyekshanbe = (e) => {
    setyekshanbe({ ...yekshanbe, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimeyekshanbe = (e) => {
    setyekshanbe({ ...yekshanbe, endTime: e.target.value });
    setSaveTime(true);
  };
  const [yekshanbeF, setyekshanbeF] = useState(false);

  //doshanbe
  const [doshanbe, setdoshanbe] = useState({ startTime: null, endTime: null });
  const startTimedoshanbe = (e) => {
    setdoshanbe({ ...doshanbe, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimedoshanbe = (e) => {
    setdoshanbe({ ...doshanbe, endTime: e.target.value });
    setSaveTime(true);
  };
  const [doshanbeF, setdoshanbeF] = useState(false);

  //seshanbe
  const [seshanbe, setseshanbe] = useState({ startTime: null, endTime: null });
  const startTimeseshanbe = (e) => {
    setseshanbe({ ...seshanbe, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimeseshanbe = (e) => {
    setseshanbe({ ...seshanbe, endTime: e.target.value });
    setSaveTime(true);
  };
  const [seshanbeF, setseshanbeF] = useState(false);

  //charshanbe
  const [charshanbe, setcharshanbe] = useState({
    startTime: null,
    endTime: null,
  });
  const startTimecharshanbe = (e) => {
    setcharshanbe({ ...charshanbe, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimecharshanbe = (e) => {
    setcharshanbe({ ...charshanbe, endTime: e.target.value });
    setSaveTime(true);
  };
  const [charshanbeF, setcharshanbeF] = useState(false);

  //panshanbe
  const [panshanbe, setpanshanbe] = useState({
    startTime: null,
    endTime: null,
  });
  const startTimepanshanbe = (e) => {
    setpanshanbe({ ...panshanbe, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimepanshanbe = (e) => {
    setpanshanbe({ ...panshanbe, endTime: e.target.value });
    setSaveTime(true);
  };
  const [panshanbeF, setpanshanbeF] = useState(false);

  //jome
  const [jome, setjome] = useState({ startTime: null, endTime: null });
  const startTimejome = (e) => {
    setjome({ ...jome, startTime: e.target.value });
    setSaveTime(true);
  };
  const endTimejome = (e) => {
    setjome({ ...jome, endTime: e.target.value });
    setSaveTime(true);
  };
  const [jomeF, setjomeF] = useState(false);

  //ALL DAY
  ////////////////////// checkbox button
  const shanbefull = (e) => {
    if (e.target.checked) {
      setshanbe({ startTime: "00:30", endTime: "24:00" });
      setshanbeF(true);
    } else {
      setshanbe({ startTime: null, endTime: null });
      setshanbeF(false);
    }
    setSaveTime(true);
  };
  const yekfull = (e) => {
    if (e.target.checked) {
      setyekshanbe({ startTime: "00:30", endTime: "24:00" });
      setyekshanbeF(true);
    } else {
      setyekshanbe({ startTime: null, endTime: null });
      setyekshanbeF(false);
    }
    setSaveTime(true);
  };
  const dofull = (e) => {
    if (e.target.checked) {
      setdoshanbe({ startTime: "00:30", endTime: "24:00" });
      setdoshanbeF(true);
    } else {
      setdoshanbe({ startTime: null, endTime: null });
      setdoshanbeF(false);
    }
    setSaveTime(true);
  };
  const sefull = (e) => {
    if (e.target.checked) {
      setseshanbe({ startTime: "00:30", endTime: "24:00" });
      setseshanbeF(true);
    } else {
      setseshanbe({ startTime: null, endTime: null });
      setseshanbeF(false);
    }
    setSaveTime(true);
  };
  const charfull = (e) => {
    if (e.target.checked) {
      setcharshanbe({ startTime: "00:30", endTime: "24:00" });
      setcharshanbeF(true);
    } else {
      setcharshanbe({ startTime: null, endTime: null });
      setcharshanbeF(false);
    }
    setSaveTime(true);
  };
  const panjfull = (e) => {
    if (e.target.checked) {
      setpanshanbe({ startTime: "00:30", endTime: "24:00" });
      setpanshanbeF(true);
    } else {
      setpanshanbe({ startTime: null, endTime: null });
      setpanshanbeF(false);
    }
    setSaveTime(true);
  };
  const jomefull = (e) => {
    if (e.target.checked) {
      setjome({ startTime: "00:30", endTime: "24:00" });
      setjomeF(true);
    } else {
      setjome({ startTime: null, endTime: null });
      setjomeF(false);
    }
    setSaveTime(true);
  };

  // tatil part
  const [tatil, setTatil] = useState({
    shanbe: false,
    ye_shanbe: false,
    do_shanbe: false,
    se_shanbe: false,
    char_shanbe: false,
    panj_shanbe: false,
    jome: false,
  });

  const tatilHandler = (e, daynumb) => {
    if (e.target.checked) {
      if (daynumb === 0) {
        setTatil({ ...tatil, shanbe: true });
        setshanbe({ startTime: null, endTime: null });
      } else if (daynumb === 1) {
        setTatil({ ...tatil, ye_shanbe: true });
        setyekshanbe({ startTime: null, endTime: null });
      } else if (daynumb === 2) {
        setTatil({ ...tatil, do_shanbe: true });
        setdoshanbe({ startTime: null, endTime: null });
      } else if (daynumb === 3) {
        setTatil({ ...tatil, se_shanbe: true });
        setseshanbe({ startTime: null, endTime: null });
      } else if (daynumb === 4) {
        setTatil({ ...tatil, char_shanbe: true });
        setcharshanbe({ startTime: null, endTime: null });
      } else if (daynumb === 5) {
        setTatil({ ...tatil, panj_shanbe: true });
        setpanshanbe({ startTime: null, endTime: null });
      } else if (daynumb === 6) {
        setTatil({ ...tatil, jome: true });
        setjome({ startTime: null, endTime: null });
      }
    } else {
      if (daynumb === 0) {
        setTatil({ ...tatil, shanbe: false });
      } else if (daynumb === 1) {
        setTatil({ ...tatil, ye_shanbe: false });
      } else if (daynumb === 2) {
        setTatil({ ...tatil, do_shanbe: false });
      } else if (daynumb === 3) {
        setTatil({ ...tatil, se_shanbe: false });
      } else if (daynumb === 4) {
        setTatil({ ...tatil, char_shanbe: false });
      } else if (daynumb === 5) {
        setTatil({ ...tatil, panj_shanbe: false });
      } else if (daynumb === 6) {
        setTatil({ ...tatil, jome: false });
      }
    }
    setSaveTime(true);
  };

  // address states
  const [valueCountry, setValueCountry] = useState(null);
  const [inputValueCountry, setInputValueCountry] = useState("");
  const [valueProvince, setValueProvince] = useState(null);
  const [inputValueProvince, setInputValueProvince] = useState("");
  const [valueCity, setValueCity] = useState(null);
  const [inputValueCity, setInputValueCity] = useState("");

  const [Countries, setCountries] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [Cities, setCities] = useState([]);

  const [CountryId, setCountryId] = useState("");
  const [ProvinceId, setProvinceId] = useState("");

  const [inputDisable, setInputDisable] = useState(true);

  const [PostalCode, setPostalCode] = useState("");
  const [PhoneCode, setPhoneCode] = useState("");

  const [AddressData, setAddressData] = useState({
    country: "",
    province: "0",
    city: "0",
    AddressLine1: "",
    PostalCode: "",
    AddressLine2: "",
    baId: "",
  });

  const headerAddress = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    // Bizid: JSON.parse(localStorage.getItem("OwnerDetail")).BizId,
    LangId: langIdState,
    Bizid: props.BizId,
    CountryId: CountryId,
    ProvinceId: ProvinceId,
  };

  const PostalHandeler = (event) => {
    if (event.target.value) {
      // setErrorPostalCode("");
    }
    setAddressData({ ...AddressData, PostalCode: event.target.value });
    setSaveAdd(true);
  };
  // address useeffect

  useEffect(() => {
    if (inputValueCountry != "") {
      axios
        .get(configData.API_DOMAIN + "/api/v1/Provinces/" + CountryId, {
          headers: {
            ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
            LangId: langIdState,
          },
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setProvinces(response.data.data);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [CountryId]);

  useEffect(() => {
    if (
      inputValueProvince != "" &&
      inputValueCountry != "" &&
      valueProvince != null
    ) {
      axios
        // .get(domain + "/api/city", {
        .get(configData.API_DOMAIN + "/api/v1/Cities/" + ProvinceId, {
          headers: {
            ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
            LangId: langIdState,
          },
        })
        .then((response) => {
          setCities(response.data.data);
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [ProvinceId]);
  // end address states
  const [MapApi, setMapApi] = useState("");
  const [Add, setAdd] = useState("");
  const [Des, setDes] = useState({ FDes: "", EDes: "" });
  const [phonestd, setPhonestd] = useState("");
  const [prefixCode, setPrefixCode] = useState("");

  //load api header
  const loadHeader = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: langIdState,
    Authorization: getCookie("token") ? "Bearer " + getCookie("token") : "",
  };
  //api header
  // const header2 = {
  //   ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
  //   LangId: langIdState,
  //   BizId: props.BizId,
  // };

  const [daysApiGet, setDaysApiGet] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const [daysHour, setdaysHour] = useState([]);
  const [Phone, setPhone] = useState([]);
  const [PhoneDatail, setPhoneDatail] = useState([]);
  const [PhoneLength, setPhoneLength] = useState(0);

  const [Title, setTitle] = useState({
    EnJobTitle: "",
    FaJobTitle: "",
    EnTitle: "",
    FaTitle: "",
  });

  const [isOnline, setOnline] = useState(false);
  const [isFull, setFull] = useState(false);
  const clickHandler1 = () => {
    setOnline(!isOnline);
    setSaveTime(true);
  };
  const clickHandler2 = () => {
    setFull(!isFull);
    setSaveTime(true);
  };
  const [isOpen, setOpen] = useState(false);
  const [isClose, setClose] = useState(false);
  const clickHandler3 = () => {
    if (!isOpen) {
      //false
      setOpen(true);
      setClose(false);
    } else {
      //true
      setOpen(false);
      setClose(false);
    }
    setSaveTime(true);
  };
  const clickHandler4 = () => {
    if (!isClose) {
      //false
      setClose(true);
      setOpen(false);
    } else {
      //true
      setClose(false);
      setOpen(false);
    }
    setSaveTime(true);
  };

  const [dataCame, setDataCame] = useState(false);

  useEffect(() => {
    if (basicModal) {
      // empty data st
      setData({
        address: "",
        phone: { 0: 0 },
        website: { 0: "" },
        time: {},
        desc: "",
        Name: "",
        FName: "",
        Job: "",
        FJob: "",
      });

      setDataCame(false);

      axios
        .get(
          configData.API_DOMAIN +
            "/api/v1/BusinessesEditSuggestion/" +
            props.id,
          { headers: loadHeader }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            setDataCame(true);

            let bizData = response.data.data.business;
            let addressData = response.data.data.address;
            let contactData = response.data.data.contact;
            let workData = response.data.data.workTime;
            // start busisness data
            setTitle({
              EnJobTitle: bizData.enJobTitle,
              FaJobTitle: bizData.faJobTitle,
              EnTitle: bizData.enTitle,
              FaTitle: bizData.faTitle,
            });
            setDes({
              EDes: bizData.enDescription,
              FDes: bizData.faDescription,
            });
            // end busisness data

            // start address data
            setAdd(addressData.addressLine1);
            setAddressData({
              ...AddressData,
              AddressLine1: addressData.addressLine1,
              AddressLine2: addressData.addressLine2,
              PostalCode: addressData.postalCode,
              country: addressData.countryTitle,
              province: addressData.provinceTitle,
              city: addressData.cityTitle,
              baId: addressData.baId,
            });
            setLocationData({
              Lat: addressData.lat,
              Lng: addressData.lng,
            });

            if (addressData.countryTitle && addressData.countryTitle !== "") {
              setInputDisable(false);
              setValueCountry({
                id: addressData.countryId,
                title: addressData.countryTitle,
                postalStd: addressData.postalStd,
              });
              setCountryId(addressData.countryId);
              setPostalCode(addressData.postalStd);
              // setPhoneCode(addressData.valueCountry.PhoneStd); اضافه کن حتما ++++
              axios
                .get(configData.API_DOMAIN + "/api/v1/Countries", {
                  headers: loadHeader,
                })
                .then((response) => {
                  if (response.data.isSuccess) {
                    setCountries(response.data.data);
                  }
                })
                .catch((error) => {
                  // alert(error);
                });

              axios
                .get(
                  configData.API_DOMAIN +
                    "/api/v1/Provinces/" +
                    addressData.countryId,
                  {
                    headers: {
                      ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
                      LangId: langIdState,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.isSuccess) {
                    setProvinces(response.data.data);
                  }
                })
                .catch((error) => {
                  // alert(error);
                });

              if (
                addressData.provinceTitle != null &&
                addressData.provinceTitle != ""
              ) {
                setValueProvince({
                  id: addressData.provinceId,
                  title: addressData.provinceTitle,
                });
                setProvinceId(addressData.provinceId);
                axios
                  // .get(domain + "/api/city", {
                  .get(
                    configData.API_DOMAIN +
                      "/api/v1/Cities/" +
                      addressData.provinceId,
                    {
                      headers: {
                        ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
                        LangId: langIdState,
                      },
                    }
                  )
                  .then((response) => {
                    setCities(response.data.data);
                  })
                  .catch((error) => {
                    // alert(error);
                  });

                if (
                  addressData.cityTitle != null &&
                  addressData.cityTitle != ""
                ) {
                  setValueCity({
                    id: addressData.cityId,
                    title: addressData.cityTitle,
                  });
                }
              }
            } else {
              axios
                .get(configData.API_DOMAIN + "/api/v1/Countries", {
                  headers: loadHeader,
                })
                .then((response) => {
                  if (response.data.isSuccess) {
                    setCountries(response.data.data);
                  }
                })
                .catch((error) => {
                  // alert(error);
                });
            }
            // end address data

            //  start contact data
            setData({
              ...data,
              website: { 0: contactData.webDetail.webSite },
            });
            setPhonestd(
              contactData.phones[0] ? contactData.phones[0].phoneStd : ""
            );
            setPrefixCode(
              contactData.phones[0] ? contactData.phones[0].prefixCode : ""
            );

            var counter2_loop = 1;
            let phoneLength = 0;

            for (let index = 0; index < contactData.phones.length; index++) {
              const element = contactData.phones[index];
              if (element.number_RSA !== "") {
                if (!Phone.includes(element.number_RSA)) {
                  setPhone((Phone) => [...Phone, element.number_RSA]);
                  // setCounter2(counter2 + 1);
                  counter2_loop = counter2_loop + 1;
                  phoneLength = phoneLength + 1;
                }
              }
            }
            setPhoneDatail(contactData.phones);

            if (
              (contactData.phones[0].number_RSA &&
                contactData.phones[0].number_RSA !== "") ||
              (contactData.phones[1].number_RSA &&
                contactData.phones[1].number_RSA !== "") ||
              (contactData.phones[2].number_RSA &&
                contactData.phones[2].number_RSA !== "") ||
              (contactData.phones[3].number_RSA &&
                contactData.phones[3].number_RSA !== "")
            ) {
              // setCounter2((prevState) => prevState - 1);
              counter2_loop = counter2_loop - 1;
            }
            setCounter2(counter2_loop);
            setPhoneLength(phoneLength);
            // end contact data

            // start work time data
            setDaysApiGet(workData.days);
            setdaysHour(workData.hours);
            setClose(!workData.holidayStatus);
            setOpen(workData.holidayStatus);
            setOnline(workData.isOnline);
            setFull(workData.open24Hours);

            var times = workData.days;
            if (times[5].open24Hours) {
              setshanbeF(true);
            } else {
              setshanbeF(false);
            }
            if (times[6].open24Hours) {
              setyekshanbeF(true);
            } else {
              setyekshanbeF(false);
            }
            if (times[0].open24Hours) {
              setdoshanbeF(true);
            } else {
              setdoshanbeF(false);
            }
            if (times[1].open24Hours) {
              setseshanbeF(true);
            } else {
              setseshanbeF(false);
            }
            if (times[2].open24Hours) {
              setcharshanbeF(true);
            } else {
              setcharshanbeF(false);
            }
            if (times[3].open24Hours) {
              setpanshanbeF(true);
            } else {
              setpanshanbeF(false);
            }
            if (times[4].open24Hours) {
              setjomeF(true);
            } else {
              setjomeF(false);
            }

            setTatil({
              shanbe: times[5].isClosed,
              ye_shanbe: times[6].isClosed,
              do_shanbe: times[0].isClosed,
              se_shanbe: times[1].isClosed,
              char_shanbe: times[2].isClosed,
              panj_shanbe: times[3].isClosed,
              jome: times[4].isClosed,
            });

            setshanbe({
              startTime: times[5].startTime,
              endTime: times[5].stopTime,
            });
            setyekshanbe({
              startTime: times[6].startTime,
              endTime: times[6].stopTime,
            });
            setdoshanbe({
              startTime: times[0].startTime,
              endTime: times[0].stopTime,
            });
            setseshanbe({
              startTime: times[1].startTime,
              endTime: times[1].stopTime,
            });
            setcharshanbe({
              startTime: times[2].startTime,
              endTime: times[2].stopTime,
            });
            setpanshanbe({
              startTime: times[3].startTime,
              endTime: times[3].stopTime,
            });
            setjome({
              startTime: times[4].startTime,
              endTime: times[4].stopTime,
            });
            // end work time data
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }
  }, [basicModal]);

  // submit btns
  const Name_Job_handler = (part) => {
    // event.preventDefault();

    if (part == "Name") {
      if (
        (Title.FaTitle !== "" && Title.FaTitle !== null) ||
        (Title.EnTitle !== "" && Title.EnTitle !== null)
      ) {
        setSaveName(false);

        const dataPost = {
          bId: props.id,
          faTitle: Title.FaTitle ? Title.FaTitle : "",
          enTitle: Title.EnTitle ? Title.EnTitle : "",
        };

        axios
          // .post(domain + "/api/enduser/titles-update", dataPost, {
          .post(
            configData.API_DOMAIN + "/api/v1/BusinessesEditSuggestion/title",
            dataPost,
            {
              headers: loadHeader,
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              alertHandeler();
              setSaveName(false);
            } else {
              setSaveName(true);
            }
          })
          .catch((error) => {
            // alert(error);
          });
      }
    } else if (part == "Job") {
      if (
        (Title.FaJobTitle !== "" && Title.FaJobTitle !== null) ||
        (Title.EnJobTitle !== "" && Title.EnJobTitle !== null)
      ) {
        setSaveJob(false);

        const dataPost = {
          bId: props.id,
          faJobTitle: Title.FaJobTitle ? Title.FaJobTitle : "",
          enJobTitle: Title.EnJobTitle ? Title.EnJobTitle : "",
        };
        axios
          // .post(domain + "/api/enduser/jobtitles-update", dataPost, {
          .post(
            configData.API_DOMAIN +
              "/api/v1/BusinessesEditSuggestion/job-title",
            dataPost,
            {
              headers: loadHeader,
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              alertHandeler();
              setSaveJob(false);
            } else {
              setSaveJob(true);
            }
          })
          .catch((error) => {
            // alert(error);
          });
      }
    }
  };

  const des_handler = (event) => {
    // event.preventDefault();
    if (
      (Des.FDec !== "" && Des.FDec !== null) ||
      (Des.FDec !== "" && Des.FDec !== null)
    ) {
      setSaveDesc(false);

      const dataPost = {
        bId: props.id,
        faDescription: Des.FDes ? Des.FDes : "",
        enDescription: Des.EDes ? Des.EDes : "",
      };
      axios
        // .post(domain + "/api/enduser/desc-update", dataPost, {
        .post(
          configData.API_DOMAIN +
            "/api/v1/BusinessesEditSuggestion/description",
          dataPost,
          {
            headers: loadHeader,
          }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            alertHandeler();
            setSaveDesc(false);
          } else {
            setSaveDesc(true);
          }
        })
        .catch((error) => {
          // alert(error);
        });
    }
  };

  const address_handler = (event) => {
    // event.preventDefault();
    setSaveAdd(false);

    const dataPost = {
      bId: props.id,
      baId: AddressData.baId,
      countryId: parseInt(CountryId),
      provinceId: valueProvince ? valueProvince.id : 0,
      cityId: valueCity ? valueCity.id : 0,
      countryTitle: valueCountry ? valueCountry.title : "",
      provinceTitle: valueProvince ? valueProvince.title : "",
      cityTitle: valueCity ? valueCity.title : "",
      addressLine1: Add,
      addressLine2: AddressData.AddressLine2,
      postalCode: AddressData.PostalCode,
      lat: locationData.Lat,
      lng: locationData.Lng,
    };

    axios
      .post(
        configData.API_DOMAIN + "/api/v1/BusinessesEditSuggestion/address",
        dataPost,
        {
          headers: loadHeader,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          alertHandeler();
          setSaveAdd(false);
        } else {
          setSaveAdd(true);
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const time_handler = (event) => {
    setSaveTime(false);

    var x1, x2, x3, x4, x5, x6, x7;
    for (let index = 0; index < daysApiGet.length; index++) {
      const element = daysApiGet[index];

      if (element.dwId == 1) {
        x1 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: doshanbe.startTime,
          stopTime: doshanbe.endTime,
          open24Hours: doshanbeF,
          isClosed: tatil.do_shanbe,
          title: element.title,
        };
      } else if (element.dwId == 2) {
        x2 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: seshanbe.startTime,
          stopTime: seshanbe.endTime,
          open24Hours: seshanbeF,
          isClosed: tatil.se_shanbe,
          title: element.title,
        };
      } else if (element.dwId == 3) {
        x3 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: charshanbe.startTime,
          stopTime: charshanbe.endTime,
          open24Hours: charshanbeF,
          isClosed: tatil.char_shanbe,
          title: element.title,
        };
      } else if (element.dwId == 4) {
        x4 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: panshanbe.startTime,
          stopTime: panshanbe.endTime,
          open24Hours: panshanbeF,
          isClosed: tatil.panj_shanbe,
          title: element.title,
        };
      } else if (element.dwId == 5) {
        x5 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: jome.startTime,
          stopTime: jome.endTime,
          open24Hours: jomeF,
          isClosed: tatil.jome,
          title: element.title,
        };
      } else if (element.dwId == 6) {
        x6 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: shanbe.startTime,
          stopTime: shanbe.endTime,
          open24Hours: shanbeF,
          isClosed: tatil.shanbe,
          title: element.title,
        };
      } else if (element.dwId == 7) {
        x7 = {
          dwId: element.dwId,
          wtId: element.wtId,
          startTime: yekshanbe.startTime,
          stopTime: yekshanbe.endTime,
          open24Hours: yekshanbeF,
          isClosed: tatil.ye_shanbe,
          title: element.title,
        };
      }
    }
    setDaysApiGet([x1, x2, x3, x4, x5, x6, x7]);
    var tt = [x1, x2, x3, x4, x5, x6, x7];

    const dataPost = {
      bId: props.id,
      isOnline: isOnline,
      open24Hours: isFull,
      holidayStatus: isOpen,
      workHours: tt,
    };

    axios
      .post(
        configData.API_DOMAIN + "/api/v1/BusinessesEditSuggestion/workTime",
        dataPost,
        {
          headers: loadHeader,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          alertHandeler();
          setSaveTime(false);
        } else {
          setSaveTime(true);
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const web_handler = (event) => {
    // event.preventDefault();
    setSaveWeb(false);

    const dataPost = {
      bId: props.id,
      website: data.website[0] ? data.website[0] : "",
    };
    axios
      // .post(domain + "/api/enduser/desc-update", dataPost, {
      .post(
        configData.API_DOMAIN + "/api/v1/BusinessesEditSuggestion/website",
        dataPost,
        {
          headers: loadHeader,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          alertHandeler();
          setSaveWeb(false);
        } else {
          setSaveWeb(true);
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const phone_handler = (event) => {
    setSavePhone(false);

    let PhoneList = [];

    let ph;
    let count = 0;
    for (let index = 0; index < PhoneDatail.length; index++) {
      const element = PhoneDatail[index];
      if (Phone[index] && Phone[index] !== "") {
        let ph = {
          baId: element.baId,
          btId: element.btId,
          number: Phone[index] ? Phone[index].replace(/\D/g, "") : "",
          number_RSA: Phone[index] ? Phone[index].replace(/\D/g, "") : "",
          phoneStd: element.phoneStd,
          prefixCode: element.prefixCode,
          type: element.type,
        };

        PhoneList.push(ph);
      }
    }

    const dataPost = {
      bId: props.id,
      phones: PhoneList,
    };

    axios
      // .post(domain + "/api/enduser/desc-update", dataPost, {
      .post(
        configData.API_DOMAIN + "/api/v1/BusinessesEditSuggestion/phone",
        dataPost,
        {
          headers: loadHeader,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          alertHandeler();
          setSavePhone(false);
        } else {
          setSavePhone(true);
        }
      })
      .catch((error) => {
        // alert(error);
      });
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  // show address map
  const [ShowMap, setShowMap] = useState(false);
  // to go to form when map saved
  useEffect(() => {
    setShowMap(false);
  }, [locationData]);

  return (
    <>
      {/* <button
        onClick={toggleShow}
        className="business-edit-responsive hov-shadow d-md-none d-flex"
      >
        پیشنهاد ویرایش کسب‌وکار
      </button> */}
      <MDBBtn onClick={toggleShow} className="business-ownership-btn2 ">
        ویرایش اطلاعات
      </MDBBtn>
      <MDBModal
        show={basicModal}
        setShow={setBasicModal}
        tabIndex="-1"
        dir="ltr"
        className="bg-modal"
      >
        <MDBModalDialog centered className="eb-modal-dialog zindex-10000">
          <MDBModalContent className="eb-modal-content">
            <div id="firstM">
              <MDBModalHeader className="eb-modal-header">
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
                <img
                  onClick={toggleShow}
                  className="eb-modal-closeimg"
                  src={closee}
                  alt="close"
                />
                {/* <MDBIcon fas icon="times" onClick={toggleShow} /> */}
              </MDBModalHeader>
              {dataCame ? (
                <MDBModalBody className="centerr eb-modal-body">
                  <MDBBtn
                    onClick={() => Handeler("NameM")}
                    className="btn-modal-data hov-shadow-btn"
                    color="success"
                  >
                    ویرایش نام کسب‌وکار
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => Handeler("JobM")}
                    className="btn-modal-data hov-shadow-btn"
                    color="success"
                  >
                    ویرایش عنوان شغلی
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      Handeler("AddressM");
                      setShowMap(false);
                    }}
                    className="btn-modal-data hov-shadow-btn"
                    color="success"
                  >
                    ویرایش آدرس
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => Handeler("callM")}
                    className="btn-modal-data hov-shadow-btn"
                    color="success"
                  >
                    ویرایش شماره تماس
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => Handeler("siteM")}
                    className="btn-modal-data hov-shadow-btn"
                    color="success"
                  >
                    ویرایش وبسایت
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => Handeler("timeM")}
                    className="btn-modal-data hov-shadow-btn"
                    color="success"
                  >
                    ویرایش ساعات کاری
                  </MDBBtn>
                  <MDBBtn
                    color="success"
                    onClick={() => Handeler("discriptionM")}
                    className="btn-modal-data hov-shadow-btn"
                  >
                    ویرایش توضیحات
                  </MDBBtn>
                </MDBModalBody>
              ) : (
                <MDBModalBody className="centerr eb-modal-body">
                  <Skeleton
                    className="mb-3 radius-2 mx-3"
                    height={45}
                    variant="rectangular"
                    animation="wave"
                  />
                  <Skeleton
                    className="mb-3 radius-2 mx-3"
                    height={45}
                    variant="rectangular"
                    animation="wave"
                  />
                  <Skeleton
                    className="mb-3 radius-2 mx-3"
                    height={45}
                    variant="rectangular"
                    animation="wave"
                  />
                  <Skeleton
                    className="mb-3 radius-2 mx-3"
                    height={45}
                    variant="rectangular"
                    animation="wave"
                  />
                  <Skeleton
                    className="mb-3 radius-2 mx-3"
                    height={45}
                    variant="rectangular"
                    animation="wave"
                  />
                  <Skeleton
                    className=" radius-2 mx-3"
                    height={45}
                    variant="rectangular"
                    animation="wave"
                  />
                </MDBModalBody>
              )}
            </div>

            {/* Businsess Name */}
            <div className="display-modal-none" dir="ltr" id="NameM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
                <img
                  onClick={() => goFirst("NameM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
              </MDBModalHeader>
              <MDBModalBody className="eb-modal-body2 eb-modal-body">
                <div className="d-flex justify-content-end">
                  <p className="eb-modal-tit1">ویرایش نام کسب‌وکار </p>
                  <img src={ibizname} alt="..." className="eb-modal-icon" />
                </div>
                <p className="eb-modal-lan text-start mt-0">English</p>
                <input
                  name="EnTitle"
                  dir="ltr"
                  value={Title.EnTitle}
                  //  {...console.log(inputValues[index])}
                  onChange={handleNameAndJob}
                  className="eb-modal-input font-family-en"
                  type="text"
                />
                <p className="eb-modal-lan">فارسی</p>
                <input
                  name="FaTitle"
                  dir="rtl"
                  value={Title.FaTitle}
                  //  {...console.log(inputValues[index])}
                  onChange={handleNameAndJob}
                  className="eb-modal-input"
                  type="text"
                />

                <div className="centerr eb-modal-bottom">
                  <hr />
                  <button
                    disabled={!saveName}
                    onClick={() => {
                      Name_Job_handler("Name");
                    }}
                    className={
                      saveName
                        ? "eb-modal-button-active eb-modal-button hov-shadow"
                        : "eb-modal-button"
                    }
                  >
                    ذخیره تغییرات
                  </button>
                </div>
              </MDBModalBody>
            </div>

            {/* Job Title */}
            <div className="display-modal-none" dir="ltr" id="JobM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
                <img
                  onClick={() => goFirst("JobM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
              </MDBModalHeader>
              <MDBModalBody className="eb-modal-body2 eb-modal-body">
                <div className="d-flex justify-content-end">
                  <p className="eb-modal-tit1">ویرایش عنوان شغلی</p>
                  <img src={ibizjob} alt="..." className="eb-modal-icon" />
                </div>
                <p className="eb-modal-lan text-start mt-0">English</p>
                <input
                  name="EnJobTitle"
                  // value={data.address}
                  value={Title.EnJobTitle}
                  //  {...console.log(inputValues[index])}
                  onChange={handleNameAndJob}
                  className="eb-modal-input font-family-en"
                  dir="ltr"
                  type="text"
                  placeholder="Example: Immigration Lawyer, Persian Restaurant, Cardiologist..."
                />
                <p className="eb-modal-lan">فارسی</p>
                <input
                  name="FaJobTitle"
                  // value={data.address}
                  value={Title.FaJobTitle}
                  //  {...console.log(inputValues[index])}
                  onChange={handleNameAndJob}
                  className="eb-modal-input"
                  dir="rtl"
                  type="text"
                  placeholder="مثال: وکیل مهاجرت، رستوران ایرانی، متخصص قلب..."
                />

                <div className="centerr eb-modal-bottom">
                  <hr />
                  <button
                    disabled={!saveJob}
                    onClick={() => {
                      Name_Job_handler("Job");
                    }}
                    className={
                      saveJob
                        ? "eb-modal-button-active eb-modal-button hov-shadow"
                        : "eb-modal-button"
                    }
                  >
                    ذخیره تغییرات
                  </button>
                </div>
              </MDBModalBody>
            </div>

            {/* Address */}
            <div className="display-modal-none" dir="rtl" id="AddressM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <img
                  onClick={() => goFirst("AddressM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
              </MDBModalHeader>
              {ShowMap ? (
                <MDBModalBody className="eb-modal-body-map eb-modal-body position-relative">
                  <div
                    className="eb-modal-body-map-close"
                    onClick={() => {
                      setShowMap((prevState) => !prevState);
                    }}
                  >
                    <img src={closee_black} alt="close" />
                  </div>

                  <DraggableMap
                    locationData={locationData}
                    setLocationData={setLocationData}
                    setSaveAdd={setSaveAdd}
                  />
                </MDBModalBody>
              ) : (
                <MDBModalBody className="eb-modal-body-scroll">
                  <div className="eb-modal-body-scroll-div">
                    <div className="d-flex justify-content-start">
                      <img src={iaddress} alt="..." className="eb-modal-icon" />
                      <p className="eb-modal-tit1">ویرایش آدرس</p>
                    </div>

                    {/* new part */}
                    <div className="text-start" dir="ltr">
                      {/* <div className=" auto10 ps-md-2 color-resp-dash-bus-add w-input-add-pro width-sm-100 mb-3 mb-md-0 "> */}
                      <p className="eb-modal-lan text-start mb-0 mt-0 d-unset">
                        Country
                      </p>

                      <div className="auto10 auto10-new color-resp-dash-bus-add w-input-add-pro width-sm-100 width-unset">
                        <Autocomplete
                          // open={false}
                          value={valueCountry}
                          onChange={(event, newValue) => {
                            setValueCountry(newValue);
                            setValueProvince(null);
                            setValueCity(null);
                            setCountryId(newValue.id);
                            setInputDisable(false);
                            setPostalCode(newValue.postalStd);
                            setPhoneCode(newValue.phoneStd);
                            setSaveAdd(true);
                            if (newValue.id !== valueCountry.id) {
                              setProvinces([]);
                            }
                            setCities([]);
                            setProvinceId("");
                          }}
                          inputValue={inputValueCountry}
                          onInputChange={(event, newInputValue) => {
                            setInputValueCountry(newInputValue);
                            setData({ ...data, country: newInputValue });
                          }}
                          popupIcon={
                            <img
                              src={iconn}
                              className="icon-ib icon-ib-new10"
                              alt="category img"
                            />
                          }
                          disableClearable
                          disablePortal
                          openOnFocus={true}
                          id="auto8"
                          options={Countries}
                          getOptionLabel={(option) => option.title}
                          className=""
                          sx={{
                            fontFamily: "inherit",
                          }}
                          renderInput={(params) => (
                            <div className="d-flex width-sm-100">
                              <TextField
                                id="auto8"
                                // className={
                                //   // errors.country && touched.country
                                //   //   ? "auto-error"
                                //   //   :
                                //   // "mt-2"
                                // }
                                {...params}
                                // label="کشور"
                                placeholder="Country"
                              >
                                {" "}
                              </TextField>
                            </div>
                          )}
                        />
                      </div>
                      <p className="eb-modal-lan text-start mb-0 mt-2 d-unset">
                        Province / State
                      </p>

                      <div className=" auto10 auto10-new color-resp-dash-bus-add w-input-add-pro width-sm-100 width-unset">
                        <Autocomplete
                          // open={true}
                          // value={data.province}
                          value={valueProvince}
                          onChange={(event, newValue) => {
                            if (
                              newValue.id !==
                              (valueProvince && valueProvince.id)
                            ) {
                              setCities([]);
                            }
                            setValueProvince(newValue);
                            setValueCity(null);
                            setProvinceId(newValue.id);
                            setSaveAdd(true);
                          }}
                          inputValue={inputValueProvince}
                          onInputChange={(event, newInputValue) => {
                            setInputValueProvince(newInputValue);
                            setData({ ...data, province: newInputValue });
                          }}
                          // onChange={(event, newValue) => {
                          //   setData({
                          //     ...data,
                          //     province: newValue.ProvinceName,
                          //   });
                          //   setProvinceId(newValue.Id);
                          // }}
                          popupIcon={
                            <img
                              src={iconn}
                              className="icon-ib icon-ib-new10"
                              alt="category img"
                            />
                          }
                          // disabled={inputDisable}
                          disableClearable
                          disablePortal
                          openOnFocus={true}
                          id="auto8"
                          options={Provinces}
                          getOptionLabel={(option) => option.title}
                          className=""
                          sx={{
                            fontFamily: "inherit",
                          }}
                          renderInput={(params) => (
                            <div className="d-flex width-sm-100">
                              <TextField
                                id="auto8"
                                className={" label-field-more"}
                                {...params}
                                // label={"استان / ایالت "}
                                placeholder="Province / State"
                              >
                                {" "}
                              </TextField>
                            </div>
                          )}
                        />
                      </div>
                      <p className="eb-modal-lan text-start mb-0 mt-2 d-unset">
                        City
                      </p>

                      <div className=" auto10 auto10-new color-resp-dash-bus-add w-input-add-pro width-sm-100 width-unset">
                        <Autocomplete
                          // open={true}
                          // value={data.city}
                          // onChange={(event, newValue) => {
                          //   setData({ ...data, city: newValue.CityName });
                          // }}
                          value={valueCity}
                          onChange={(event, newValue) => {
                            setValueCity(newValue);
                            setSaveAdd(true);
                          }}
                          inputValue={inputValueCity}
                          onInputChange={(event, newInputValue) => {
                            setInputValueCity(newInputValue);
                            setData({ ...data, city: newInputValue });
                          }}
                          popupIcon={
                            <img
                              src={iconn}
                              className="icon-ib icon-ib-new10"
                              alt="category img"
                            />
                          }
                          // disabled={inputDisable}
                          disableClearable
                          disablePortal
                          openOnFocus={true}
                          id="auto8"
                          options={Cities}
                          getOptionLabel={(option) => option.title}
                          className=""
                          sx={{
                            fontFamily: "inherit",
                          }}
                          renderInput={(params) => (
                            <div className="d-flex width-sm-100">
                              <TextField
                                id="auto8"
                                className={"mb-0 "}
                                {...params}
                                // label="شهر"
                                placeholder="City"
                              >
                                {" "}
                              </TextField>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <p className="eb-modal-lan text-start mt-2 d-unset">
                        Address Line
                      </p>
                      <input
                        dir="ltr"
                        // value={data.address}
                        value={Add}
                        //  {...console.log(inputValues[index])}
                        onChange={handleAdd}
                        className="eb-modal-input introduce-placeholder mt-0 font-family-en"
                        type="text"
                        placeholder="Address Line"
                      />

                      <p className="eb-modal-lan text-start mt-2 d-unset">
                        Postal / Zip Code
                      </p>
                      <InputMask
                        formatChars={formatChar}
                        mask={PostalCode}
                        // maskChar={null}
                        value={AddressData.PostalCode}
                        onChange={PostalHandeler}
                        // disabled={inputDisable}
                      >
                        {(inputProps) => (
                          <input
                            dir="ltr"
                            // value={data.address}
                            value={AddressData.PostalCode}
                            //  {...console.log(inputValues[index])}
                            onChange={PostalHandeler}
                            className="eb-modal-input introduce-placeholder font-family-en"
                            type="text"
                            placeholder="Postal / Zip Code"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>

                    <div className="text-start">
                      <div className="eb-modal-map-text">
                        <div>
                          <img src={map_icon} alt="map" />
                          <span className="mt-1">
                            لطفا لوکیشن کسب‌وکار را روی{" "}
                            <span
                              className="eb-modal-map-btn"
                              onClick={() => {
                                setShowMap((prevState) => !prevState);
                              }}
                            >
                              نقشه
                            </span>{" "}
                            پین کنید.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="centerr eb-modal-bottom-scroll">
                    <hr />
                    <button
                      disabled={!saveAdd}
                      onClick={address_handler}
                      className={
                        saveAdd
                          ? "eb-modal-button-active eb-modal-button hov-shadow"
                          : "eb-modal-button"
                      }
                    >
                      ذخیره تغییرات
                    </button>
                  </div>
                </MDBModalBody>
              )}
            </div>

            {/* call */}
            <div className="display-modal-none" dir="rtl" id="callM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <img
                  onClick={() => goFirst("callM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
              </MDBModalHeader>
              <MDBModalBody className="eb-modal-body2 eb-modal-body">
                <div className="d-flex justify-content-start">
                  <img src={icall} alt="..." className="eb-modal-icon" />
                  <p className="eb-modal-tit1">ویرایش شماره تماس</p>
                </div>
                {Array.from(Array(counter2)).map((c, index) => {
                  return (
                    <div className="centerr position-relative" key={index}>
                      {/* <input
                        dir="ltr"
                        value={Phone[index]}
                        //  {...console.log(inputValues[index])}
                        onChange={handleOnChange2}
                        key={c}
                        id={index}
                        className="eb-modal-input"
                        type="text"
                      /> */}
                      <InputMask
                        formatChars={formatChar}
                        mask={phonestd}
                        // maskChar={null}
                        value={Phone[index]}
                        onChange={handleOnChange2}
                        // disabled={inputDisable}
                        key={c}
                        id={index}
                      >
                        {(inputProps) => (
                          // <input
                          //   dir="ltr"
                          //   value={Phone[index]}
                          //   //  {...console.log(inputValues[index])}
                          //   onChange={handleOnChange2}
                          //   key={c}
                          //   id={index}
                          //   className="eb-modal-input"
                          //   type="text"
                          //   {...inputProps}
                          // />
                          <TextField
                            value={Phone[index]}
                            //  {...console.log(inputValues[index])}
                            onChange={handleOnChange2}
                            key={c}
                            // id={index}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {prefixCode}
                                </InputAdornment>
                              ),
                            }}
                            dir="ltr"
                            type="text"
                            id="outlined-basic"
                            variant="outlined"
                            className="eb-modal-input p-0 font-family-en"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                      {index >= PhoneLength && (
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleClick2Delete(index)}
                          className="eb-modal-input-delete"
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </div>
                  );
                })}
                <div className="d-flex justify-content-center">
                  <div className="eb-modal-add1">
                    {showCounter2 ? (
                      <button
                        className="eb-modal-add hov-shadow-btn "
                        onClick={handleClick2}
                      >
                        <img
                          src={add1}
                          alt="add"
                          className="eb-modal-add-img"
                        />
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="centerr eb-modal-bottom">
                  <hr />
                  <button
                    disabled={!savePhone}
                    onClick={phone_handler}
                    className={
                      savePhone
                        ? "eb-modal-button-active eb-modal-button hov-shadow"
                        : "eb-modal-button"
                    }
                  >
                    ذخیره تغییرات
                  </button>
                </div>
              </MDBModalBody>
            </div>

            {/* site */}
            <div className="display-modal-none" dir="rtl" id="siteM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <img
                  onClick={() => goFirst("siteM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
              </MDBModalHeader>
              <MDBModalBody className="eb-modal-body2 eb-modal-body">
                <div className="d-flex justify-content-start">
                  <img src={iglobe} alt="..." className="eb-modal-icon" />
                  <p className="eb-modal-tit1">ویرایش وبسایت</p>
                </div>
                {Array.from(Array(counter)).map((c, index) => {
                  return (
                    <div className="centerr" key={index}>
                      <input
                        dir="ltr"
                        value={data.website[index]}
                        //  {...console.log(inputValues[index])}
                        onChange={handleOnChange}
                        key={c}
                        id={index}
                        className="eb-modal-input"
                        type="text"
                      />
                    </div>
                  );
                })}
                {/* <div className="d-flex justify-content-center">
                  <div className="eb-modal-add1">
                    {showCounter ? (
                      <button
                        className="eb-modal-add hov-shadow-btn "
                        onClick={handleClick}
                      >
                        <img
                          src={add1}
                          alt="add"
                          className="eb-modal-add-img"
                        />
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                <div className="centerr eb-modal-bottom">
                  <hr />
                  <button
                    disabled={!saveWeb}
                    onClick={web_handler}
                    className={
                      saveWeb
                        ? "eb-modal-button-active eb-modal-button hov-shadow"
                        : "eb-modal-button"
                    }
                  >
                    ذخیره تغییرات
                  </button>
                </div>
              </MDBModalBody>
            </div>

            {/* time */}
            <div className="display-modal-none" dir="rtl" id="timeM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <img
                  onClick={() => goFirst("timeM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
              </MDBModalHeader>
              <MDBModalBody className="eb-modal-body-scroll">
                <div className="eb-modal-body-scroll-div">
                  <div className="d-flex justify-content-start">
                    <img src={itime} alt="..." className="eb-modal-icon" />
                    <p className="eb-modal-tit1">ویرایش ساعات کاری</p>
                  </div>

                  <div className="rb-s5-group2">
                    <button
                      className={
                        isOnline
                          ? "rb-s5-group2-btn-on"
                          : "rb-s5-group2-btn-off"
                      }
                      onClick={clickHandler1}
                    >
                      کسب‌و‌کار آنلاین است
                    </button>
                    <button
                      className={
                        isFull ? "rb-s5-group2-btn-on" : "rb-s5-group2-btn-off"
                      }
                      onClick={clickHandler2}
                    >
                      کسب‌و‌کار شبانه‌روزی است
                    </button>
                  </div>
                  {/*  */}
                  <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>
                      <div className="s5-res-w-res">
                        <div className="eb-modal-right">
                          <MDBRow className="">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[0].title
                                  ? daysApiGet[0].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4"
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={doshanbeF || tatil.do_shanbe}
                                  value={doshanbe.startTime}
                                  onChange={startTimedoshanbe}
                                  id="3/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        doshanbe.endTime &&
                                        doshanbe.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={doshanbeF || tatil.do_shanbe}
                                  className=""
                                  value={doshanbe.endTime}
                                  onChange={endTimedoshanbe}
                                  id="3/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        doshanbe.startTime &&
                                        doshanbe.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.do_shanbe
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.do_shanbe}
                                onChange={dofull}
                                defaultChecked={daysApiGet[0].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !doshanbeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={doshanbeF}
                                onChange={(e) => tatilHandler(e, 2)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          <MDBRow className="">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[1].title
                                  ? daysApiGet[1].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4"
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={seshanbeF || tatil.se_shanbe}
                                  value={seshanbe.startTime}
                                  onChange={startTimeseshanbe}
                                  id="4/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        seshanbe.endTime &&
                                        seshanbe.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={seshanbeF || tatil.se_shanbe}
                                  value={seshanbe.endTime}
                                  onChange={endTimeseshanbe}
                                  id="4/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        seshanbe.startTime &&
                                        seshanbe.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.se_shanbe
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.se_shanbe}
                                onChange={sefull}
                                defaultChecked={daysApiGet[1].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !seshanbeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={seshanbeF}
                                onChange={(e) => tatilHandler(e, 3)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          <MDBRow className="">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[2].title
                                  ? daysApiGet[2].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4"
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={charshanbeF || tatil.char_shanbe}
                                  value={charshanbe.startTime}
                                  onChange={startTimecharshanbe}
                                  id="5/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        charshanbe.endTime &&
                                        charshanbe.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={charshanbeF || tatil.char_shanbe}
                                  value={charshanbe.endTime}
                                  onChange={endTimecharshanbe}
                                  id="5/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        charshanbe.startTime &&
                                        charshanbe.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.char_shanbe
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.char_shanbe}
                                onChange={charfull}
                                defaultChecked={daysApiGet[2].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !charshanbeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={charshanbeF}
                                onChange={(e) => tatilHandler(e, 4)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          <MDBRow className="">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[3].title
                                  ? daysApiGet[3].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4"
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={panshanbeF || tatil.panj_shanbe}
                                  value={panshanbe.startTime}
                                  onChange={startTimepanshanbe}
                                  id="6/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        panshanbe.endTime &&
                                        panshanbe.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={panshanbeF || tatil.panj_shanbe}
                                  value={panshanbe.endTime}
                                  onChange={endTimepanshanbe}
                                  id="6/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        panshanbe.startTime &&
                                        panshanbe.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.panj_shanbe
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.panj_shanbe}
                                onChange={panjfull}
                                defaultChecked={daysApiGet[3].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !panshanbeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={panshanbeF}
                                onChange={(e) => tatilHandler(e, 5)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          <MDBRow className="">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[4].title
                                  ? daysApiGet[4].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4"
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={jomeF || tatil.jome}
                                  value={jome.startTime}
                                  onChange={startTimejome}
                                  id="7/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        jome.endTime &&
                                        jome.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={jomeF || tatil.jome}
                                  value={jome.endTime}
                                  onChange={endTimejome}
                                  id="7/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        jome.startTime &&
                                        jome.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.jome
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.jome}
                                onChange={jomefull}
                                defaultChecked={daysApiGet[4].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !jomeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={jomeF}
                                onChange={(e) => tatilHandler(e, 6)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          <MDBRow className=" ">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[5].title
                                  ? daysApiGet[5].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4"
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={shanbeF || tatil.shanbe}
                                  value={shanbe.startTime}
                                  onChange={startTimeShanbe}
                                  id="1/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        shanbe.endTime &&
                                        shanbe.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={shanbeF || tatil.shanbe}
                                  value={shanbe.endTime}
                                  onChange={endTimeShanbe}
                                  id="1/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        shanbe.startTime &&
                                        shanbe.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.shanbe
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.shanbe}
                                onChange={shanbefull}
                                defaultChecked={daysApiGet[5].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !shanbeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={shanbeF}
                                onChange={(e) => tatilHandler(e, 0)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          <MDBRow className=" ">
                            <p className="pmodal">
                              {daysApiGet.length > 0
                                ? daysApiGet[6].title
                                  ? daysApiGet[6].title
                                  : ""
                                : ""}
                            </p>
                            <div className="d-flex justify-content-md-start ">
                              <FormControl
                                className="ms-4 "
                                id="step5-select1"
                                sx={{ m: 1 }}
                              >
                                <Select
                                  disabled={yekshanbeF || tatil.ye_shanbe}
                                  value={yekshanbe.startTime}
                                  onChange={startTimeyekshanbe}
                                  id="2/1"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        yekshanbe.endTime &&
                                        yekshanbe.endTime <= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl id="step5-select1" sx={{ m: 1 }}>
                                <Select
                                  disabled={yekshanbeF || tatil.ye_shanbe}
                                  className=""
                                  value={yekshanbe.endTime}
                                  onChange={endTimeyekshanbe}
                                  id="2/2"
                                  // displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="" className="s5-items">
                                    <em>
                                      <p></p>
                                    </em>
                                  </MenuItem>
                                  {daysHour.map((item) => (
                                    <MenuItem
                                      className="s5-items"
                                      key={item.hour}
                                      value={item.hour}
                                      disabled={
                                        yekshanbe.startTime &&
                                        yekshanbe.startTime >= item.hour
                                      }
                                    >
                                      {item.hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </MDBRow>
                          <div className="d-flex">
                            <label
                              className={
                                !tatil.ye_shanbe
                                  ? "container-s5 "
                                  : "container-s5 disabled "
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={tatil.ye_shanbe}
                                onChange={yekfull}
                                defaultChecked={daysApiGet[6].open24Hours}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">24 ساعته </span>
                            </label>
                            <label
                              className={
                                !yekshanbeF
                                  ? "container-s5  me-4 pe-3"
                                  : "container-s5 disabled  me-4 pe-3"
                              }
                            >
                              <input
                                type="checkbox"
                                disabled={yekshanbeF}
                                onChange={(e) => tatilHandler(e, 1)}
                                defaultChecked={daysApiGet[0].isClosed}
                              />
                              <span className="s5-24h"></span>
                              <span className="s5-modal24">تعطیل </span>
                            </label>
                          </div>
                          {/* <input
                          type="checkbox"
                          className="mt-4 s5-24h"
                          onChange={jomefull}
                        />{" "}
                        <span className="s5-modal24">24 ساعته  </span> */}
                        </div>
                      </div>
                    </ThemeProvider>
                  </CacheProvider>

                  <p className="rb-step5-tit2">تعطیلات رسمی دولتی:</p>
                  <MDBBtnGroup className="rb-s5-group">
                    <button
                      className={
                        isClose ? "rb-s5-group-btn-on" : "rb-s5-group-btn-off"
                      }
                      onClick={clickHandler4}
                    >
                      بسته است
                    </button>
                    <button
                      className={
                        isOpen ? "rb-s5-group-btn-on" : "rb-s5-group-btn-off"
                      }
                      onClick={clickHandler3}
                    >
                      باز است
                    </button>
                  </MDBBtnGroup>
                </div>

                <div className="centerr eb-modal-bottom-scroll">
                  <hr />
                  {/* <button disabled={true} className="eb-modal-button2"> */}
                  <button
                    disabled={!saveTime}
                    onClick={time_handler}
                    className={
                      saveTime
                        ? "eb-modal-button-active eb-modal-button2 hov-shadow"
                        : "eb-modal-button2"
                    }
                  >
                    ذخیره تغییرات
                  </button>
                </div>
              </MDBModalBody>
            </div>

            {/* description */}
            <div className="display-modal-none" dir="rtl" id="discriptionM">
              <MDBModalHeader className="Header-modal-data eb-modal-header">
                <img
                  onClick={() => goFirst("discriptionM")}
                  src={arroww}
                  alt="back"
                  className="eb-modal-backimg"
                />
                <h4 className="header-modal-data">پیشنهاد ویرایش اطلاعات</h4>
              </MDBModalHeader>
              <MDBModalBody className="eb-modal-body2 eb-modal-body">
                <div className="d-flex justify-content-start align-items-center">
                  <img src={idescription} alt="..." className="eb-modal-icon" />
                  <p className="eb-modal-tit1">ویرایش توضیحات</p>
                </div>
                <p className="eb-modal-lan text-start mt-0">English</p>
                <textarea
                  dir="ltr"
                  name="EDes"
                  value={Des.EDes}
                  onChange={handleDesc}
                  className="eb-modal-textarea font-family-en"
                  cols="30"
                  rows=" "
                ></textarea>
                <p className="eb-modal-lan">فارسی</p>
                <textarea
                  dir="rtl"
                  name="FDes"
                  value={Des.FDes}
                  onChange={handleDesc}
                  className="eb-modal-textarea"
                  cols="30"
                  rows=" "
                ></textarea>
                <div className="centerr eb-modal-bottom">
                  <hr />
                  <button
                    disabled={!saveDesc}
                    onClick={des_handler}
                    className={
                      saveDesc
                        ? "eb-modal-button-active eb-modal-button hov-shadow"
                        : "eb-modal-button"
                    }
                  >
                    ذخیره تغییرات
                  </button>
                </div>
              </MDBModalBody>
            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* </div> */}
      {/* alert delete */}
      <AlertSuccess
        showAlert={showAlert}
        text={" اطلاعات شما با موفقیت ثبت شد."}
      />
    </>
  );
}
