import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
//
import { DomainContext, LangIdContext } from "../../App";
import { validate } from "../../components/login/validate";
// config
import configData from "../../config/config.json";
// components
import MetaTagsInfoPage from "../../components/meta-tags-seo/MetaTagsInfoPage";
import Header2 from "../../components/header/Header2";
import Headermobile from "../../components/header-mobile/Headermobile";
import Footer from "../../components/footer/Footer";
import AlertSuccess from "../../components/Dashbord/AlertSuccess/AlertSuccess";
import NotFound from "../NotFound/NotFound";
// rtl
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const Advertise = (props) => {
  const [showAlert, setshowAlert] = useState(false);
  // data
  const [data, setData] = useState({
    Business: "",
    Namee: "",
    Countryy: "",
    Cityy: "",
    Emaill: "",
    Phone: "",
  });
  const inputChangeHandler = (event) => {
    setData({ ...data, [event.target.name]: [event.target.value] });
  };
  // validate
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setErrors(validate(data, "advertise"));
  }, [data, touched]);

  const submitHandler = (event) => {
    event.preventDefault();

    if (!Object.keys(errors).length) {
      // success
      // props.history.push({
      //   pathname: "/",
      // });
      alertHandeler();
    } else {
      setTouched({
        Business: true,
        Countryy: true,
        Cityy: true,
        Emaill: true,
      });
    }
  };
  // alert
  const alertHandeler = () => {
    setshowAlert(true);
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setshowAlert(false);
    }, 4000);
  };

  // for domain of Apis
  const domain = useContext(DomainContext);
  // the langId for header of Apis
  const { langIdState, setLangIdState } = useContext(LangIdContext);

  const [ApiDataCome, setApiDataCome] = useState({
    headerbiz: false,
    footer: false,
  });
  const [footerData, setFooterData] = useState({});
  const [headerData, setheaderData] = useState({
    headerLogo: {
      title: "",
      link: "",
    },
  });

  const [pageContent, setPageContent] = React.useState("");

  // this 2 satae for Meta Tags
  const [MTagsCome, setMTagsCome] = useState(false);
  const [MetaData, setMetaData] = React.useState({});

  //api header
  const header = {
    ApiKey: "87e4d3a91a554a2196ae8bdf0bfdcdc9",
    LangId: getCookie("_gapp_LId") || langIdState,
    FooterId: props.match.params.id,
  };
  useEffect(() => {
    // 1 page content
    axios
      // .get(domain + "/api/footer/menu-content", { headers: header })
      .get(
        configData.API_DOMAIN +
          `/api/v1/Contents?FooterId=${props.match.params.id}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setMTagsCome(true);
          setMetaData(response.data.data.metaTag);
          setPageContent(response.data.data.itemContent);
        } else {
          setPageContent("0");
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 2 header-other
    axios
      .get(configData.API_DOMAIN + "/api/v1/Headers/other", {
        headers: header,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setheaderData(response.data.data);
          setApiDataCome((ApiDataCome) => ({
            ...ApiDataCome,
            headerbiz: true,
          }));
        }
      })
      .catch((error) => {
        // alert(error);
      });
    // 3 footer
    axios
      .get(configData.API_DOMAIN + "/api/v1/Footers", {
        headers: header,
      })
      .then((response) => {
        setFooterData(response.data.data);
        setApiDataCome((ApiDataCome) => ({ ...ApiDataCome, footer: true }));
      })
      .catch((error) => {
        // alert(error);
      });
  }, []);

  //getCookie
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <>
      {pageContent === "0" ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          {MTagsCome ? <MetaTagsInfoPage data={MetaData} /> : ""}

          <div className="d-none d-md-block">
            <Header2 data={headerData} dataCome={ApiDataCome.headerbiz} />
          </div>
          <div className="d-block d-md-none">
            <div className="fixed-header">
              <Headermobile data={headerData} />
            </div>
            <div className="box-under-header-res"></div>
          </div>
          {/* header */}
          <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
              <div className="gish-page-back">
                {/* <div className="gish-con-tit">
              تبلیغات در <span className="color-5ae6a2"> گیشنیز</span>
            </div> */}
                <MDBContainer>
                  <div dangerouslySetInnerHTML={{ __html: pageContent }} />
                </MDBContainer>
                <div className="gish-con-body">
                  {/* <p>
                برای کسب اطلاعات از پکیجهای تبلیغاتی گیشنیز، اطلاعات خود را وارد
                کنید. <br className="d-md-none" />
                تیم مارکتینگ گیشنیز بزودی با شما تماس می‌گیرد.
              </p> */}
                  <div className="mt-4">
                    <div>
                      <TextField
                        name="Business"
                        value={data.Business}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="نام کسب وکار"
                        variant="outlined"
                        className={
                          errors.Business && touched.Business
                            ? "label-field-more7 custom-text-field custom-text-field-contact custom-text-field-error"
                            : "label-field-more7 custom-text-field custom-text-field-contact mb-0"
                        }
                      />
                    </div>
                    {errors.Business && touched.Business && (
                      <span className="input-error input-error-con me-1">
                        {errors.Business}
                      </span>
                    )}

                    <div className="mt-3">
                      <TextField
                        name="Namee"
                        value={data.Namee}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="نام و نام‌خانوادگی"
                        variant="outlined"
                        className={
                          errors.Namee && touched.Namee
                            ? "label-field-more10 custom-text-field custom-text-field-contact custom-text-field-error"
                            : "label-field-more10 custom-text-field custom-text-field-contact mb-0"
                        }
                      />
                    </div>
                    {errors.Namee && touched.Namee && (
                      <span className="input-error input-error-con me-1">
                        {errors.Namee}
                      </span>
                    )}

                    <div className="">
                      <TextField
                        type="text"
                        name="Countryy"
                        value={data.Countryy}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="کشور"
                        variant="outlined"
                        className={
                          errors.Countryy && touched.Countryy
                            ? "label-field-less1 custom-text-field custom-text-field-contact custom-text-field-error mt-3"
                            : "label-field-less1 custom-text-field custom-text-field-contact mb-0 mt-3"
                        }
                      />
                    </div>
                    {errors.Countryy && touched.Countryy && (
                      <span className="input-error input-error-con me-1">
                        {errors.Countryy}
                      </span>
                    )}

                    <div className="">
                      <TextField
                        type="text"
                        name="Cityy"
                        value={data.Cityy}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="شهر"
                        variant="outlined"
                        className={
                          errors.Cityy && touched.Cityy
                            ? "label-field-less1 custom-text-field custom-text-field-contact custom-text-field-error mt-3"
                            : "label-field-less1 custom-text-field custom-text-field-contact mb-0 mt-3"
                        }
                      />
                    </div>
                    {errors.Cityy && touched.Cityy && (
                      <span className="input-error input-error-con me-1">
                        {errors.Cityy}
                      </span>
                    )}
                    <div className="">
                      <TextField
                        type="email"
                        name="Emaill"
                        value={data.Emaill}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="ایمیل"
                        variant="outlined"
                        className={
                          errors.Emaill && touched.Emaill
                            ? "label-field-less custom-text-field custom-text-field-contact custom-text-field-error mt-3"
                            : "label-field-less custom-text-field custom-text-field-contact mb-0 mt-3"
                        }
                      />
                    </div>
                    {errors.Emaill && touched.Emaill && (
                      <span className="input-error input-error-con me-1">
                        {errors.Emaill}
                      </span>
                    )}

                    <div className="mt-3">
                      <TextField
                        error
                        name="Phone"
                        value={data.Phone}
                        onChange={inputChangeHandler}
                        id="outlined-basic"
                        label="تلفن"
                        variant="outlined"
                        className={
                          errors.Phone && touched.Phone
                            ? "label-field-less custom-text-field custom-text-field-contact custom-text-field-error"
                            : "label-field-less custom-text-field custom-text-field-contact mb-0"
                        }
                      />
                    </div>
                  </div>
                  <MDBBtn
                    // to=""
                    className="hov-shadow-btn con-btn"
                    onClick={submitHandler}
                  >
                    ثبت
                  </MDBBtn>
                </div>
              </div>
            </ThemeProvider>
          </CacheProvider>
          <Footer data={footerData} dataCome={ApiDataCome.footer} />

          <AlertSuccess
            showAlert={showAlert}
            text={" اطلاعات شما با موفقیت ثبت شد."}
          />
        </>
      )}
    </>
  );
};

export default Advertise;
