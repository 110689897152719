import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardOverlay,
  MDBCardImage,
} from "mdb-react-ui-kit";
import Skeleton from "@mui/material/Skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OverlayCard = (props) => {
  return (
    // background="dark"
    <MDBCard className=" h-100   over-top-card ">
      <a
        href={props.url}
        className=" hov-shadow2 text-white h-100 bg-image hover-zoom"
        target="_blank"
      >
        {!props.datacome ? (
          <Skeleton
            variant="rectangular"
            className="over-top-card  h-100 maxh-blog"
            animation="wave"
          />
        ) : (
          <>
            {/* <MDBCardImage */}
            <LazyLoadImage
              // PlaceholderSrc={
              //   "https://cdn.gishniz.com/business/1/1820/Cover_202281510232610557514.png"
              // }
              src={props.img}
              alt={props.title}
              overlay="true"
              className="card-img over-top-card  h-100 maxh-blog"
            />

            <MDBCardOverlay
              className=" mask gradient-darkk "
              style={{
                background:
                  "linear-gradient(180deg,rgba(27,28,30,0) 0%,rgba(27,28,30,0.8) 90% ",
              }}
            >
              <div className="over-text ">
                <MDBCardTitle className="fs-22 fs-xs-22 fw-bold">
                  خبر
                </MDBCardTitle>
                <MDBCardTitle className="fs-16 fs-xs-15 fw-bold pt-1 ">
                  {" "}
                  {props.title}
                </MDBCardTitle>
              </div>
            </MDBCardOverlay>
          </>
        )}

        {/* inside MDBCardTitle */}
        {/* <MDBCardText className='fs-14 fs-xs-14'>ساسکاچوان که یکی از تنها دو استان محصور در خشکی در کانادا است و به‌خاطر...</MDBCardText> */}
      </a>
    </MDBCard>
  );
};

export default OverlayCard;
