import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ZoomControl,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import "./search-map.css";
import MapPopupCard from "../map-popup-card/MapPopupCard";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useLocation } from "react-router-dom";
import ChangeView from "../draggable-map/ChangeView";
import { LatLngBounds } from "leaflet";
// import i from "../../images/pin location.png"

//  Animated panning
function SetViewOnClick({ animateRef }) {
  const map = useMapEvent("click", (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    });
  });

  return null;
}

function GetIcon(_iconSize) {
  return L.icon({
    iconUrl: require("../../images/pin location.png"),
    iconSize: [_iconSize],
  });
}

const SetMapBounds = ({ points }) => {
  const map = useMap();

  if (points.length > 0 && points[0]) {
    const bounds = new LatLngBounds(points);
    map.fitBounds(bounds);
  }

  return null;
};

const SearchMap = (props) => {
  const { bizList, pageChanged, MTagsCome } = props;
  const animateRef = useRef(true);
  const [show, setShow] = useState(false);
  const [markCick, setmarkCick] = useState(false);
  const [popData, setPopData] = useState({});

  // const eventHandlers = () => {
  //   console.log("marker clicked!!!");
  // };

  // zoom with location
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const find_loc = queryParams.get("find_loc");

  const [zoomLevel, setZoomLevel] = useState(3);
  const [mapCenter, setMapCenter] = useState([49.01095, -96.615234]);

  const mapRef = useRef(null);
  const map = mapRef.current;

  // const map = useMap();

  useEffect(() => {
    if (find_loc !== "") {
      let find_loc2 = find_loc.split("-").join(" ");

      let url = `https://nominatim.openstreetmap.org/search.php?q=${find_loc2}&polygon_geojson=1&format=jsonv2`;
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          if (json.length > 0) {
            let lat = parseFloat(json[0].lat);
            let lon = parseFloat(json[0].lon);
            setMapCenter([lat, lon]);

            // if (json[0].place_rank <= 10) {
            //   setZoomLevel(json[0].place_rank);
            // } else if (json[0].place_rank <= 12) {
            //   setZoomLevel(json[0].place_rank - 2);
            // } else if (json[0].place_rank <= 13) {
            //   setZoomLevel(json[0].place_rank - 3);
            // } else if (json[0].place_rank <= 15) {
            //   setZoomLevel(json[0].place_rank - 4);
            // } else if (json[0].place_rank <= 20) {
            //   setZoomLevel(json[0].place_rank - 6);
            // } else {
            //   setZoomLevel(16);
            // }
          }
        });
    } else {
      setMapCenter([49.01095, -96.615234]);
      setZoomLevel(3);
    }
  }, [find_loc]);

  const [bounds, setBounds] = useState([
    // [
    //   [43.85286110000001, -79.43382088255609],
    //   [43.768748000000016, -79.47433399999997],
    // ],
    // [
    //   // [49.31428187645317, -123.07066998489348],
    //   // [0, 0],
    //   // [43.9469917, -79.4545656],
    //   // [43.85851530000002, -79.38338128255614],
    //   // [53.62893349999999, 174.8337270000001],
    //   // [43.85286110000001, -79.43382088255609],
    //   // [43.8554583, -79.3864068],
    //   // [0, 0],
    //   // [43.766055400000006, -79.4746584],
    //   // [43.768748000000016, -79.47433399999997],
    // ],
  ]);

  useEffect(() => {
    // console.log("bizlist");
    // console.log(bizList[0].bId);

    if (!pageChanged && bizList[0] && bizList[0].bId !== 1) {
      let arr = [];
      arr.push(
        bizList.map((item) => {
          if (item.lat !== 0 && item.lng !== 0) {
            return [item.lat, item.lng];
          }
        })
      );
      setBounds(arr);

      // setBounds([
      //   [
      //     [49.31428187645317, -123.07066998489348],
      //     [0, 0],
      //     [43.9469917, -79.4545656],
      //     [43.85851530000002, -79.38338128255614],
      //     [53.62893349999999, 174.8337270000001],
      //     [43.85286110000001, -79.43382088255609],
      //     [43.8554583, -79.3864068],
      //     [0, 0],
      //     [43.766055400000006, -79.4746584],
      //     [43.768748000000016, -79.47433399999997],
      //   ],
      // ]);
      // console.log("arrrrr");
      // console.log(arr);
    }

    setShow(false);
  }, [pageChanged]);

  // console.log("map********zoomLevel-----");
  // console.log(zoomLevel);
  // console.log(mapCenter);

  let height = "100vh";

  if (typeof window !== "undefined") {
    // console.log("height---------------------vh1");
    // console.log(window.innerHeight);
    if (props.windowWidth > 765) {
      height = "calc(100vh - 203.4px)";
    } else {
      height = `calc(${window.innerHeight}px - 145.5px)`;
    }
  } else {
    if (props.windowWidth > 765) {
      // console.log("height---------------------vh2");
      // console.log(window.innerHeight);
      height = "calc(100vh - 203.4px)";
    } else {
      height = "calc(100vh - 145.5px)";
    }
  }

  return (
    <div
      className="fixed-map  "
      onClick={() => {
        if (markCick) {
        } else {
          if (show) {
            setShow(false);
          }
        }
        setmarkCick(false);
      }}
    >
      {!pageChanged && bounds[0] ? (
        <>
          <MapContainer
            ref={mapRef}
            center={[49.01095, -96.615234]}
            zoom={3}
            scrollWheelZoom={true}
            zoomControl={false}
            className="map-leaflet"
            style={{ height: height }}
            // bounds={bounds[0]}
            // bounds={[
            //   [49.253777, -122.893533],
            //   [49.28037974, -123.1227709],
            // ]}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {!show && <SetMapBounds points={bounds[0]} />}

            {/* <ClickAwayListener onClickAway={setShow(false)}>
          <Box> */}
            {bizList.length > 0 &&
              bizList.map((item) =>
                item.lat == 0 && item.lng == 0 ? (
                  ""
                ) : (
                  <>
                    <Marker
                      eventHandlers={{
                        click: () => {
                          setMapCenter([item.lat, item.lng]);
                          setZoomLevel(map.getZoom());

                          setShow(true);
                          setmarkCick(true);
                          setPopData(item);
                        },
                      }}
                      position={[item.lat, item.lng]}
                      icon={GetIcon(37)}
                    ></Marker>
                  </>
                )
              )}
            {/* </Box>
        </ClickAwayListener> */}

            {show && <ChangeView center={mapCenter} zoom={zoomLevel} />}

            {/* Zome Control */}
            {props.windowWidth == "00" ? (
              false
            ) : props.windowWidth > 765 ? (
              <div className="d-none">
                <ZoomControl position="bottomright" />
              </div>
            ) : (
              ""
            )}

            {/* Animated panning */}
            <SetViewOnClick animateRef={animateRef} />
          </MapContainer>
          {show ? (
            <div className="marker1-popup">
              <MapPopupCard
                title={popData.title}
                desc2={popData.location}
                rate={popData.rate}
                img={popData.photoPath}
                url={popData.url}
                datacome={true}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <Skeleton
          variant="rectangular"
          className="map-leaflet map-leaflet-skeleton"
          animation="wave"
        />
      )}
    </div>
  );
};

export default SearchMap;
