import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCardText,
} from "mdb-react-ui-kit";
import Skeleton from "@mui/material/Skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Rec2Card = (props) => {
  return (
    <a href={props.url} target="_blank">
      <MDBCard
        className={
          props.bus
            ? "p-0 color-101010 shadow-off hov-shadow card-border rec2-card maxw-100"
            : "p-0 color-101010 shadow-off hov-shadow card-border rec2-card"
        }
      >
        <MDBRow className="g-0 height-100">
          <MDBCol className="col-sm-4 col-5">
            {!props.datacome ? (
              <Skeleton
                variant="rectangular"
                className="img-r2-card height-100"
                animation="wave"
              />
            ) : (
              <LazyLoadImage
                // PlaceholderSrc={
                //   "https://cdn.gishniz.com/business/1/1820/Cover_202281510232610557514.png"
                // }
                src={props.img}
                alt={props.title}
                className="img-fluid img-r2-card"
              />
            )}
          </MDBCol>
          <MDBCol className="col-sm-8 col-7">
            <MDBCardBody className="my-2  p-0 color-101010 ">
              <MDBCardTitle className="fw-bold mx-3 ms-2 pt_0_new pt-1 mx-md-4 line-height-154 fs-15 fs-xs-14  ">
                {!props.datacome ? (
                  <Skeleton
                    className="skeleton-text-rec2"
                    variant="text"
                    sx={{ fontSize: "1.4rem" }}
                    // width={props.bus ? 110 : 140}
                    animation="wave"
                  />
                ) : (
                  props.title
                )}
              </MDBCardTitle>
              {!props.datacome ? (
                <div className="position-relative d-block margin-t-41px">
                  <Skeleton
                    className="mx-md-4 mx-3"
                    variant="text"
                    sx={{ fontSize: "0.6rem" }}
                    animation="wave"
                  />
                  <Skeleton
                    className="mx-md-4 mx-3"
                    variant="text"
                    sx={{ fontSize: "0.6rem" }}
                    animation="wave"
                  />
                </div>
              ) : (
                <MDBCardText
                  className="text-bottom2 text-bottom me-md-4 ms-2 me-3 fs-13 fs-xs-12 show-2line"
                  // dangerouslySetInnerHTML={{__html : `${text.slice(0, 89)} ...`}}
                  dangerouslySetInnerHTML={{ __html: props.desc }}
                >
                  {/* {props.desc} */}
                </MDBCardText>
              )}
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </a>
  );
};

export default Rec2Card;
