import React, { useState, useRef, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
} from "mdb-react-ui-kit";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  ZoomControl,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
// img
import arrow from "../../images/business/arrow.svg";
import direction from "../../images/business/direction.svg";
import back from "../../images/business/nextblue.svg";
// import back from "../../images/next-left.png";

//  Animated panning
function SetViewOnClick({ animateRef }) {
  const map = useMapEvent("click", (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    });
  });

  return null;
}

function GetIcon(_iconSize) {
  return L.icon({
    iconUrl: require("../../images/pin location.png"),
    iconSize: [_iconSize],
  });
}

const BizMapModalMobile = (props) => {
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);

  //   for map
  const animateRef = useRef(true);
  const [show, setShow] = useState(false);
  const [markCick, setmarkCick] = useState(false);
  const [popData, setPopData] = useState({});

  // for image map the make modal open
  const mapRef = useRef(null);
  const map = mapRef.current;

  if (map != null) {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
  }

  // function openMaps(latitude, longitude) {
  //   console.log("11")
  //   console.log(latitude)
  //   console.log(longitude)
  //   var url = "";

  //   // Check if the user is on iOS
  //   var isIOS =
  //     /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  //     console.log("22")
  //     console.log(isIOS)

  //   if (isIOS) {
  //     console.log("33")

  //     url = "http://maps.apple.com/?ll=" + latitude + "," + longitude;
  //   } else {
  //     console.log("44")

  //     // If not iOS, assume it's Android
  //     url = "geo:" + latitude + "," + longitude;
  //   }
  //   console.log("55")

  //   window.open(url);
  // }

  // directio btn
  const directionHandler = () => {
    L.Routing.control({
      waypoints: [L.latLng(49.2827, -123.1188), L.latLng(43.6532, -123.118)],
    }).addTo(map);
  };

  const mapRef2 = useRef(null);
  const map2 = mapRef2.current;

  if (map2 != null) {
    setTimeout(function () {
      map2.invalidateSize();
    }, 10);
  }

  useEffect(() => {
    // for direction and get user location
    // if ("geolocation" in navigator) {
    //   console.log("Available");
    //   var x = "";
    //   var x2 = "";
    //   navigator.geolocation.getCurrentPosition(
    //     function (position) {
    //       console.log("Latitude is");
    //       console.log("Latitude is :", position.coords.latitude);
    //       console.log("Longitude is :", position.coords.longitude);
    //       x = position.coords.latitude;
    //       x2 = position.coords.longitude;
    //       if (map2 !== null) {
    //         // add marker
    //         var theMarker = new L.Marker([x, x2]).addTo(map2);
    //       }
    //     },
    //     function (error) {
    //       console.error("Error Code = " + error.code + " - " + error.message);
    //       x = props.lat;
    //       x2 = props.lng;
    //       console.log(x, x2);
    //       console.log(props.lat, props.lng);
    //       if (map2 !== null) {
    //         // add marker
    //         var theMarker = new L.Marker([x, x2]).addTo(map2);
    //       }
    //     }
    //   );
    // } else {
    //   console.log("Not Available");
    // }
  }, [map2]);

  // if (map2 != null) {
  //   map2.locate({ setView: true, maxZoom: 16 });
  // }
  return (
    <>
      {props.Add_map ? (
        <a
          className="business-information-text font-family-en"
          onClick={() => {
            if (props.lat !== 0 && props.lng !== 0) {
              toggleShow();
            }
          }}
        >
          {props.Address}
          <img className="business-information-img" src={arrow} alt="" />
        </a>
      ) : (
        <div
          onClick={() => {
            if (props.lat !== 0 && props.lng !== 0) {
              toggleShow();
            }
          }}
        >
          <MapContainer
            ref={mapRef}
            className="ifram-bus"
            center={
              props.lat &&
              props.lat !== 0 &&
              props.lat != "" &&
              props.lng &&
              props.lng !== 0 &&
              props.lng != ""
                ? [props.lat, props.lng]
                : [49.01095, -96.615234]
            }
            zoom={16}
            scrollWheelZoom={true}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              eventHandlers={{
                click: () => {
                  // console.log("marker clicked");
                  // console.log(props.lat);
                },
              }}
              position={
                props.lat &&
                props.lat !== 0 &&
                props.lat != "" &&
                props.lng &&
                props.lng !== 0 &&
                props.lng != ""
                  ? [props.lat, props.lng]
                  : [49.01095, -96.615234]
              }
              icon={GetIcon(37)}
            ></Marker>
          </MapContainer>
        </div>
      )}
      <MDBModal
        show={basicModal}
        setShow={setBasicModal}
        tabIndex="-1"
        dir="rtl"
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader className="biz-map-modal-header">
              <span></span>
              <span>{props.Title}</span>
              <MDBBtn
                className="btn-close mx-0 biz-map-modal-btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
              {/* {/* <img
                onClick={toggleShow}
                className="biz-map-modal-btn-close mx-0"
                data={back}
                alt=""
              /> */}
              {/* <object 
                onClick={toggleShow}
                className="biz-map-modal-btn-close mx-0"
                data={back}
                alt=""
                type="image/svg+xml"
              ></object> */}
            </MDBModalHeader>
            <MDBModalBody className="p-0">
              {/* <MDBBtn
                className="biz-map-modal-direction"
                // onClick={
                //   directionHandler
                //   // () => {
                //   // openMaps(props.lat, props.lng);
                //   // console.log("ddd1");
                //   // console.log(L);
                //   // console.log("ddd2");
                //   // console.log(L.Routing);
                //   // console.log("ddd3");
                //   // console.log(map);

                //   // L.Routing.control({
                //   //   waypoints: [
                //   //     L.latLng(49.2827, -123.1188),
                //   //     L.latLng(43.6532, -123.118),
                //   //   ],
                //   // }).addTo(map);
                //   // }
                // }
              >
                <object
                  className="biz-map-modal-btn-dir"
                  data={direction}
                  alt=""
                  type="image/svg+xml"
                ></object>
                مسیریابی
              </MDBBtn> */}
              {/* <MDBBtn className="biz-map-modal-direction">
                {" "}
                <a
                  href={`https://waze.com/ul?ll=${props.lat},${props.lng}&navigate=yes`}
                  target="_blank"
                >
                  Open in Waze
                </a>{" "}
              </MDBBtn> */}

              {/* map */}
              <MapContainer
                ref={mapRef2}
                center={
                  props.lat &&
                  props.lat !== 0 &&
                  props.lat != "" &&
                  props.lng &&
                  props.lng !== 0 &&
                  props.lng != ""
                    ? [props.lat, props.lng]
                    : [49.01095, -96.615234]
                }
                zoom={16}
                scrollWheelZoom={true}
                zoomControl={false}
                className="biz-map-modal-leaflet"
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  eventHandlers={{
                    click: () => {
                      // console.log("marker clicked");
                      //   setShow(true);
                      //   setmarkCick(true);
                      //   setPopData(item);
                    },
                  }}
                  position={
                    props.lat &&
                    props.lat !== 0 &&
                    props.lat != "" &&
                    props.lng &&
                    props.lng !== 0 &&
                    props.lng != ""
                      ? [props.lat, props.lng]
                      : [49.01095, -96.615234]
                  }
                  icon={GetIcon(37)}
                ></Marker>
                {/* Zome Control */}
                <div className="d-none">
                  <ZoomControl position="bottomright" />
                </div>
                {/* Animated panning */}
                <SetViewOnClick animateRef={animateRef} />
              </MapContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default BizMapModalMobile;
